




































































import { Component, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import camelCaseKeys from 'camelcase-keys'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import snakeCaseKeys from 'snakecase-keys'

import TablePagination from '@/partials/tables/Pagination.vue'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    TablePagination,
    TrashCan16,
    Edit16,
    GenericModalDelete
  }
})
export default class EventStudyReviewers extends Mixins(FlashMessageMixin, PermissionsMixin) {
  eventId = this.$route.params.id
  studyReviewers: Array<any> = []
  filteredData: Array<any> = []
  deleteModalIsOpened = false
  studyReviewerTotal = 0
  baseUrl = process.env.VUE_APP_URL
  selectedEventStudyReviewer = {
    id: '',
    name: '',
    description: ''
  }

  fetchStudyReviewers (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get(`/event/${this.$route.params.id}/study-reviewers`, {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.filteredData = this.studyReviewers = camelCaseKeys(response.data.data, { deep: true })
        this.studyReviewerTotal = response.data.total
      })
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'O Avaliador foi removido com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetchStudyReviewers()
  }

  search (searchString: string) {
    this.filteredData = this.studyReviewers.filter((studyReviewer: any) => {
      const allValues = Object.keys(studyReviewer).map(key => studyReviewer[key])

      const isMatch = allValues.some((value: string) => {
        return value.toLowerCase().includes(searchString.toLowerCase())
      })

      if (isMatch) {
        return studyReviewer
      }
    })
  }

  onPageChange (pagination: Pagination) {
    this.fetchStudyReviewers(pagination.page, pagination.length)
  }

  get deleteRequestBody () {
    return { eventUsers: [isEmpty(this.selectedEventStudyReviewer) ? '' : this.selectedEventStudyReviewer.id] }
  }

  get deleteUrl () {
    return isEmpty(this.selectedEventStudyReviewer) ? '' : `/event/${this.eventId}/study-reviewers/${this.selectedEventStudyReviewer.id}`
  }
}
